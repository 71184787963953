




import { Component, Mixins } from 'vue-property-decorator'

import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component
export default class MentorLesson extends Mixins(MasterGroupMixin, NotifyMixin, PermissionsMixin) {
  private created () {
    if (!this.hasPermission(this.Permission.EDUCATION_LESSON_VIEW) && !(this.currentMasterGroupIsPlus && this.hasPermission(this.Permission.EDUCATION_PERSONAL_CREATE))) {
      this.notifyError('У вас недостаточно прав для просмотра урока')
      this.$router.replace({ name: 'profile' })
    }
  }
}
